import React, { useEffect, useState } from "react";
import DraggableSection from "./DraggableSection"; // Adjust path as needed
import { addTask,updateTask,setTask } from "../../../store/Task/index"; // Adjust path as needed
import { useDispatch, useSelector } from "react-redux";

const SectionView = ({ sections, setSections, setTasks }) => {
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tasks.tasks);
    // Handle section input changes
    const handleInputChange = (sectionId, event) => {
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, value: event.target.value } : section
    );
    setSections(newSections);
};

    // Handle task input changes
    const handleTaskChange = (sectionId, event) => {
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, taskValue: event.target.value } : section
        );
        setSections(newSections);
    };

    // Handle changes in task rows
    const handleTaskRowChange = (taskId, field, value) => {
        dispatch(updateTask({ taskId, field, value }));
    };
    
    const addRow = (sectionId, value,sectionTags) => {
        const nonSpaceCharCount = value.replace(/\s/g, "").length;
        
        // Validation for at least 3 non-space characters
        if (nonSpaceCharCount < 3) {
            alert("Input must contain at least 3 non-space characters.");
            return; // Exit if validation fails
        }
        
        const newTaskId = `task${tasks.length + 1}`;
        const newTask = {
            id: newTaskId,
            sectionId: sectionId,
            value: value,
            status: 0, // Default status
            dueDate: "",
            organisation: "",
            assignee: "",
            comments: false,
            tags: sectionTags,
        };
        
        // Dispatch an action to add the new task to the store
        dispatch(addTask(newTask));
        // Clear task input after adding
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, taskValue: "" } : section
    );
    setSections(newSections);
    const storedTasks = JSON.parse(localStorage.getItem("Tasks")) || [];
    // dispatch(setTask(...storedTasks));
    
        // Ensure storedTasks is an array before pushing
        if (Array.isArray(storedTasks)) {
            storedTasks.unshift(newTask);
            localStorage.setItem("Tasks", JSON.stringify(storedTasks));
            // storedTasks.unshift(newTask)     
            setTasks(storedTasks)
        } else {
            // If storedTasks is not an array, overwrite it with a new array containing newTask
            localStorage.setItem("Tasks", JSON.stringify([newTask]));
            // storedTasks = [newTask]
            setTasks(storedTasks)
        }
    };
    


    // Reorder sections
    const moveSection = (fromSectionId, toSectionId) => {
        const fromIndex = sections.findIndex((section) => section.id === fromSectionId);
        const toIndex = sections.findIndex((section) => section.id === toSectionId);

        if (fromIndex >= 0 && toIndex >= 0) {
            const newSections = [...sections];
            const [movedSection] = newSections.splice(fromIndex, 1);
            newSections.splice(toIndex, 0, movedSection);
            setSections(newSections);
        }
    };    

    return (
        <div>
            {sections.map((section) => (
                <DraggableSection
                    key={section.id}    
                    id={section.id}
                    index={section.id}
                    moveSection={moveSection}
                    item={section}
                    inputValue={section.value}
                    handleInputChange={(event) => handleInputChange(section.id, event)}
                    taskValue={section.taskValue || ""}
                    handleTaskChange={(event) => handleTaskChange(section.id, event)}
                    addRow={(taskValue) => addRow(section.id, taskValue,section.tags)}
                    handleTaskRowChange={(taskId, field, value) =>
                        handleTaskRowChange(taskId, field, value)
                    }
                />
            ))}
        </div>
    );
};

export default SectionView;
