import React, { useState } from "react";
import InputBox from "../../Atom/Input/InputField";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import ImageGallery from "../../Atom/Image/Image";
import Tags from "../../Atom/Tags/tags";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import CustomDropDown from "../../Atom/CustomDropDown/CustomDropDown"
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import CommentsIcon from "../../../assets/images/svg/Comments.svg";
import { useDispatch } from "react-redux";
import { updateTask } from "../../../store/Task/index"; // Import the updateTask action

const TaskRow = ({ 
    row,
    handleStatusChange,
    handleDueDateChange,
    handleOrganisationChange,
    handleAssigneeChange,
    handleCommentsChange,
    handleTagsChange,   
    handleTaskChange
}) => {

    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(row.value || "");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar
    

    const [selectedOptions, setSelectedOptions] = useState([]);
  
  const handleOptionSelect = (options) => {
    setSelectedOptions(options);
  };





    // Enable editing on double-click
    const handleDoubleClick = () => {
        setIsEditing(true);
        setInputValue(row.value); // Reset the input value when entering edit mode
    }; 

    // Handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Save value and exit edit mode on Enter or blur
    const handleBlurOrEnter = (e) => {
        if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
            if (inputValue.length >= 3) {
                dispatch(updateTask({ id: row.id, field: 'value', value: inputValue })); 
                setIsEditing(false); 
            } else {
                alert("Input must be at least 3 characters long.");
            }
        }
    };

    // Function to handle row click: log row ID and toggle sidebar
    const handleRowClick = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle the sidebar state
        document.getElementById('openSidebar')?.click(); // Trigger the sidebar toggle if an element with id 'openSidebar' exists
    };
    const handleRemoveTag = (tagIndex) => {
        const updatedTags = row.tags.filter((_, index) => index !== tagIndex);
        dispatch(updateTask({ id: row.id, field: 'tags', value: updatedTags }));
    };

    return (
        <tr>
            <td></td>
            <td onClick={handleRowClick}>
                {isEditing ? (
                    <InputBox
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleBlurOrEnter}
                        onKeyDown={handleBlurOrEnter} // Use only onKeyDown for handling Enter
                        autoFocus
                    />
                ) : (
                    <span onDoubleClick={handleDoubleClick}>
                        {row.value || "Double-click to edit"}
                    </span>
                )}
            </td>
            <td>
                <CircularProgressBar
                    percentage={row.status}
                    onChange={(e) => handleStatusChange(e, row.id)}
                />
            </td>
            <td>
                <MyDatePicker
                    selectedDate={row.dueDate}
                    onChange={(date) => handleDueDateChange(date, row.id)}
                />
            </td>
            <td>
                {row.organisation ? (
                    <ImageGallery images={[row.organisation]} />
                ) : (
                    <img src={OrganisationIcon} alt="Organisation" />
                )}
            </td>
            <td>
                {row.assignee ? (
                    <ImageGallery images={[row.assignee]} shape="circle" />
                ) : (
                    <img src={AssigneeIcon} alt="Assignee" />
                )}
            </td>
            <td>
                <img src={CommentsIcon} alt="Comments" />
            </td>
            <td>
                <Tags
                    tags={row.tags || []}
                    onRemoveTag={handleRemoveTag} // Pass the handleRemoveTag function
                    // onRemoveTag={(tagIndex) => handleTagsChange(tagIndex, row.id)}
                   
                />
            </td>
        </tr>
    );
};

export default TaskRow;
