import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./Style.css";
import DragIcon from "../../../assets/images/svg/Drag_icon.svg";
import Edit from "../../../assets/images/svg/Edit.svg";
import InputBox from "../../Atom/Input/InputField";
import RangeBar from "../../Atom/RangBar/rangBar";
import Tags from "../../Atom/Tags/tags";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import Comments from "../../../assets/images/svg/Comments.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import Dropdown from "../../../assets/images/svg/DropDown_arrow.svg";
import TaskRow from "./TaskRow";
import Data from "../../../data.json"
import { useSelector } from "react-redux";
const ItemType = "SECTION";

const DraggableSection = ({
    id,
    index,
    moveSection,
    item,
    inputValue,
    handleInputChange,
    taskValue,
    handleTaskChange,
    addRow,
    handleTaskRowChange,
}) => {
    const [tags, setTags] = useState(item.tags);
    const [isSectionVisible, setIsSectionVisible] = useState(true);

    const tasks = useSelector((state) => state.tasks.tasks);
    const [rows, setRows] = useState([]);
    // Get tasks for a specific section
    const getTasksForSection = () => {
        const tmp = tasks.filter((task) => task.sectionId === id)
        
        setRows(tmp);
    };
    useEffect(() => {
        getTasksForSection();
    }, [tasks])

    const handleTagRemove = (index) => {
        setTags((prevTags) => prevTags.filter((_, i) => i !== index));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addRow(taskValue); // Call addRow function to add a new row
        }
    };

    const [, ref] = useDrag({
        type: ItemType,
        item: { id, index },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSection(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const toggleVisibility = () => {
        setIsSectionVisible(!isSectionVisible);
    };

    return (
        <div className="main-wrapper">
            <div ref={(node) => ref(drop(node))} className="section-header">
                <div className="section-left">
                    <img
                        className="section-drag-icon"
                        src={DragIcon}
                        alt="DragIcon"
                    />
                    <img
                        className={`section-dropdown-icon ${
                            !isSectionVisible ? "rotate" : ""
                        }`}
                        src={Dropdown}
                        alt="Dropdown"
                        onClick={toggleVisibility}
                        style={{ cursor: "pointer" }}
                    />
                    <InputBox value={inputValue} onChange={handleInputChange} />
                    <RangeBar initialValue={0} />
                </div>
                <div>
                    <img className="section-edit-icon" src={Edit} alt="EditIcon" />
                </div>
            </div>
            <div className={`section-tags ${!isSectionVisible ? "hidden" : ""}`}>
                All <Tags tags={tags} onRemoveTag={handleTagRemove} />
            </div>
            <div className={`section-table ${!isSectionVisible ? "hidden" : ""}`}>
                <table className="tasks-table">
                    <thead>
                        <tr className="tasks-table-header">
                            <th></th>
                            <th>Task</th>
                            <th>Status</th>
                            <th>Due Date</th>
                            <th>Organisation</th>
                            <th>Assignee</th>
                            <th>Comments</th>
                            <th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="tasks-table-header">
                            <td>
                                <img
                                    className="section-drag-icon"
                                    src={DragIcon}
                                    alt="DragIcon"
                                />
                            </td>
                            <td>
                                <InputBox
                                    value={taskValue}
                                    placeholder={"write a task name"}
                                    onChange={handleTaskChange}
                                    onKeyPress={handleKeyPress}
                                />
                            </td>
                            <td>
                                <CircularProgressBar percentage={0} />
                            </td>
                            <td>
                                <MyDatePicker />
                            </td>
                            <td>
                                <img src={OrganisationIcon} alt="Organisation" />
                            </td>
                            <td>
                                <img src={AssigneeIcon} alt="AssigneeIcon" />
                            </td>
                            <td>
                                <img src={Comments} alt="Comments" />
                            </td>
                            <td>
                                <Tags tags={[]} onRemoveTag={handleTagRemove} />
                            </td>
                        </tr>
                        {rows?.map((row, rowIndex) => (
                            <TaskRow
                                key={rowIndex}
                                row={row}
                                handleTaskChange={(e) => handleTaskRowChange(e, row.id)}
                                handleStatusChange={(status) => handleTaskRowChange(status, row.id)}
                                handleDueDateChange={(date) => handleTaskRowChange(date, row.id)}
                                handleOrganisationChange={(organisation) => handleTaskRowChange(organisation, row.id)}
                                handleAssigneeChange={(assignee) => handleTaskRowChange(assignee, row.id)}
                                handleCommentsChange={(comments) => handleTaskRowChange(comments, row.id)}
                                handleTagsChange={(tagIndex) => handleTaskRowChange(tagIndex, row.id)}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DraggableSection;
