import React from 'react';
import './Style.css'; // Import CSS for styling
import data from '../../../data.json'; // Your JSON data
const Tags = ({ tags = [], disabled = false, onRemoveTag }) => {
  const handleRemoveTag = (index) => {
    if (!disabled && onRemoveTag) {
      
      onRemoveTag(index); // Only call if not disabled and callback exists
    }
  };

  return (
    <div className={`tags ${disabled ? 'tags--disabled' : ''}`}>
      {tags.length === 0 ? (
        // Display message when no tags are present
        <span className={'tag--disabled'}>New tag</span>
      ) : (
        tags.map((tag, index) => (
         
          <span key={index} className={`tag ${disabled ? 'tag--disabled' : ''}`}>
          {data.tags.find((e)=>e.id === tag)?.value ?? ''}
            {!disabled && (
              // Remove button only shows if not disabled
              <button className="tag__remove-button" onClick={() => handleRemoveTag(index)}>
                &times; 
              </button>
            )}
          </span>
        ))
      )}
    </div>
  );
};

export default Tags;
